import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, IconButton, SpeedDial, SpeedDialIcon, Typography } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MenuIcon from '@mui/icons-material/Menu';
import WlFormDialog from '../waitlist/WlFormDialog'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
function createData(number, gname, guests, quote, checkin) {
  return { number, gname, guests, quote, checkin };
}

const rows = [
  createData("1", "willson", 3, 24, '09:30'),
  createData("2", "test", 2, 37, 4.3),
  createData("3", "cool dude", 1, 24, 6.0),
  createData("4", "john", 3, 67, 4.3),
  createData("5", "kate", 1.0, 49, 3.9),
];
const boxStyle = (theme) => ({
  paddingTop: theme.spacing(2),
});

const handleIcon = (event) => {
  console.log("handleIcon");
  console.log(event);
};
const History = () => {
  return (
    <Box>         
      <Box sx={boxStyle}></Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{border: 0}}>
              <TableCell>Time</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Guests</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">Checkin</TableCell>
              <TableCell align="right">Ready</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.number}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.number}
                </TableCell>
                <TableCell align="left">{row.gname}</TableCell>
                <TableCell align="right">{row.guests}</TableCell>
                <TableCell align="right">{row.quote}</TableCell>
                <TableCell align="right">{row.checkin}</TableCell>
                <TableCell align="right">{row.checkin}</TableCell>
                <TableCell align="right">
                  
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleIcon}
                    color="inherit"
                    sx={{padding: 0.5}}
                  >
                    <MessageIcon />
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={()=>handleIcon('3')}
                    color="inherit"
                    sx={{padding: 0.5}}
                  >
                    <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={()=>handleIcon('3')}
                    color="inherit"
                    sx={{padding: 0.5}}
                  >
                  
                    <MenuIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>


    </Box>
  );
};

export default History;
