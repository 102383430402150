import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import MenuIcon from "@mui/icons-material/Menu";
function ResourceTable(props) {
  const datarow =[]
  return (
    <Box>


      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ border: 0 }}>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">Concurrent</TableCell>
              <TableCell align="right">Duration</TableCell>

              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datarow.map((row) => (
              <TableRow
                key={row.id}
                hover
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >

                <TableCell align="left">
                  <Typography>{row.name}</Typography>
                  <Typography sx={{ fontSize: 13 }}>
                    {row.note ? "note:" + row.note : ""}
                  </Typography>
                </TableCell>
                <TableCell align="right">{row.guestNumber}</TableCell>
                <TableCell align="right">{row.quote}</TableCell>
                <TableCell align="right">
                  <Typography sx={{ color: "#FF5733" }}>-</Typography>
                </TableCell>

                <TableCell align="right">

                  <Tooltip title="Modify">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => {}}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Box>
  )
}

ResourceTable.propTypes = {}

export default ResourceTable
