import { Box, Button, Typography } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReservationDialog from "./ReservationDialog";
import PropTypes from "prop-types";
export const ReservationToolbar = (props) => {
  const [value, setValue] = React.useState(new Date());
  const [rsvOpen, setRsvOpen] = React.useState(false);

  const { onDateChange, waitlistid, refresh , labelData} = props;

  const handelRsvClickOpen = () => {
    setRsvOpen(true);
  };

  const handleRsvClose = () => {
    setRsvOpen(false);
    refresh();
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: 1,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Reservation Date"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              console.log(newValue);
              onDateChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handelRsvClickOpen}
          >
            Add Reservation
          </Button>
          <ReservationDialog
            open={rsvOpen}
            onClose={handleRsvClose}
            waitlistid={waitlistid}
            labelData={labelData}
          ></ReservationDialog>
        </Box>
      </Box>
    </Box>
  );
};
ReservationToolbar.propTypes = {
  waitlistid: PropTypes.number,
  onDateChange: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  labelData: PropTypes.array,
};
