import { Box, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import Online from './Online'
import ReservationEnable from './ReservationEnable'
import Resource from '../resource/Resource'

function Reservation() {
  return (
    <Box sx={{ width: "600px" }}>
      <Container>
        <Typography align="left" variant="h5">
          Reservation
        </Typography>
        <Divider></Divider>
        <ReservationEnable></ReservationEnable>
        <Online></Online>
        <br></br>
      </Container>
      <br></br><br></br>


    </Box>
  )
}

export default Reservation