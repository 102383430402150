import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'


import { useApi } from '../../../util/api';
import LabelRow from './LabelRow';
function LabelTable(props) {
  const fetchWrapper = useApi();
  const datarow = props.data;

  const [, setCount] = useState(0);

  const handleDelete = (id) => {
    console.log("delete " + id);
    props.deleteLabel(id)
  }
  const handleChange = (event) => {
   // setChecked(event.target.checked);
  };

  const handleCheckUpdate = (id, checked) => {

  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ border: 0 }}>
              <TableCell align="left">Label Name</TableCell>

              <TableCell align="right">Self Service</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {datarow.map((row) => (
              <LabelRow id={row.id} name={row.name} selfService={row.selfService}
              handleDelete={handleDelete}
              handleUpdate={props.updateLabel}></LabelRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Box>
  )
}

LabelTable.propTypes = {
  data: PropTypes.array,
  deleteLabel: PropTypes.func,
  updateLabel: PropTypes.func
}

export default LabelTable
