import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React from "react";
import { useApi } from "../../util/api";

const PasswordChange = ({ handleAlertOpen }) => {
  const fetchWrapper = useApi();
  const [formData, setFormData] = React.useState({
    oldpassword: "",
    newpassword: "",
    newpassword2: "",
  });

  const { oldpassword, newpassword, newpassword2 } = formData;
  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const body = JSON.stringify({
      oldpassword: formData.oldpassword,
      newpassword: formData.newpassword,
    });
    if (newpassword !== newpassword2) {
      handleAlertOpen("New passwords do not match.");
      return;
    }
    return fetchWrapper
      .put("/auth/changepassword", body)
      .then((data) => {
        setFormData({
          oldpassword: "",
          newpassword: "",
          newpassword2: "",
        });
      })
      .catch((mesg) => {
        handleAlertOpen(mesg);
      });
  };

  return (
    <Box sx={{ width: "400px" }}>
      <Typography align="left" variant="h5">
        Change Password
      </Typography>
      <Divider></Divider>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          id="old"
          name="oldpassword"
          label="Enter Old password"
          placeholder="Enter Old password"
          value={oldpassword}
          type="password"
          fullWidth
          margin="normal"
          size="small"
          onChange={onChange}
        />
        <TextField
          required
          id="new1"
          name="newpassword"
          label="Enter New password"
          placeholder="Enter New password"
          value={newpassword}
          type="password"
          fullWidth
          margin="normal"
          size="small"
          onChange={onChange}
        />
        <TextField
          required
          id="new2"
          name="newpassword2"
          label="Confirm New Password"
          placeholder="Confirm password"
          value={newpassword2}
          type="password"
          fullWidth
          margin="normal"
          size="small"
          onChange={onChange}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={{ margin: "8px 0" }}
        >
          Update
        </Button>
      </form>
    </Box>
  );
};

export default PasswordChange;
