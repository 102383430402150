import { Box, Grid, Container, Typography } from "@mui/material";
import React from "react";
import Login from "./Login";
import RegisterForm from "./RegisterForm";
import RegisterAbout from "./RegisterAbout";
import { maxWidth } from "@mui/system";
const containerStyle = (theme) => ({
  paddingTop: theme.spacing(10),
});
const Register = () => {
  return (
    <div>
      <Container sx={containerStyle}>
        <Typography align="center" variant="h5">
          Waitlist for your business or group
        </Typography>
        <Typography align="center" variant="h6">
          Create your free account today
        </Typography>
        

      <RegisterForm></RegisterForm>
      </Container>
      <Container maxWidth="sm">
      <Typography align="center" variant="subtitle2">
      Compatible with computing platforms with attached keyboard (includes chromebooks and android/ios devices) or standalone tablets in portrait mode 
        with a minimal aprox 768 x1024 resolution
        </Typography>
      </Container>

    </div>
  );
};

export default Register;
