import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
  Container,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { Link as RouterLink } from "react-router-dom";
import { useUserActions } from "../../action/useUserAction";
import {
  validateEmail,
  validateFullname,
  validatePassword,
  validatePhone,
} from "../../util/Validation";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { useRecoilState } from "recoil";
import { fingerprintAtom } from "../../state/fingerprintAtom";
const MContainer = styled(Container)(({ theme }) => ({
  color: "white",
  paddingTop: theme.spacing(5),
  backgroundColor: theme.palette.primary.main,
  position: "static",
  top: 0,
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "white",
    color: "#555",
    border: "0px solid #ece7e7",
  },
}));

const RegisterForm = () => {
  const fpPromise = FingerprintJS.load()
  const paperStyle = {
    padding: 20,
    height: "300",
    width: 275,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    mobile: "",
  });
  const userActions = useUserActions();
  const [open, setOpen] = React.useState(false);
  const { name, email, password, password2, mobile } = formData;
  const [errorMesg, setErrorMessage] = useState("");

  const [fingerprint, setFingerprint] = useRecoilState(fingerprintAtom)

  useEffect(() => {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        console.log(result.visitorId);
        setFingerprint(result.visitorId)
      });
  }, []);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

  


    userActions.logout2();
    try {
      validateFullname(name);
      validateEmail(email);
      validatePassword(password, password2);
      //validatePhone(mobile);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setOpen(true);
      return;
    }

    userActions.register(name, email.toLowerCase(), password, mobile, fingerprint).catch((error) => {
      console.log(error);
      setErrorMessage(error);
      setOpen(true);
    });
  };
  return (
    <Container sx={{paddingTop:"20"}}>
      <form onSubmit={handleSubmit}>
        <Grid  container
          direction="column"
          justifyContent="center"
          alignItems="center">

            <Grid align="center">
              <Avatar style={avatarStyle}>
                <PersonAddAltOutlinedIcon />
              </Avatar>
              <h2>Sign Up</h2>
            </Grid>
            <Grid item xs={12}>
            <TextField
              required
              id="name-input"
              name="name"
              label="Fullname"
              placeholder="Enter Fullname"
              value={name}
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />
            <TextField
              required
              id="email-input"
              name="email"
              label="Email"
              placeholder="Enter email"
              value={email}
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />
           {/** 
            <TextField
              required
              autoFocus
              margin="dense"
              id="mobile"
              name="mobile"
              value={mobile}
              label="Mobile (xxx-yyy-zzzz)"
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              onChange={onChange}
  />*/}
            <br></br>
            <TextField
              required
              id="password-input"
              name="password"
              label="Password"
              placeholder="Enter password"
              value={password}
              type="password"
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />
            <TextField
              required
              id="password2-input"
              name="password2"
              label="Confirm Password"
              placeholder="Confirm password"
              value={password2}
              type="password"
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
            >
              Sign Up
            </Button>
            </Grid>
            <Typography>
              {" "}
              ** Valid email necessary for account vailidation.
            </Typography>
            <Typography>
              {" "}
              Have an account already?
              <Link component={RouterLink} to="/login">
                Sign In
              </Link>
            </Typography>
            <Typography>
              {" "}
              Support: support@waitabout.com
            </Typography>
            <a target="_blank" href="https://waitabout.com/terms" title="example">Terms</a>

        </Grid>
      </form>
      <Snackbar open={open} autoHideDuration={15000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: "500px" }}
        >
          {errorMesg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RegisterForm;
