import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import StatsCard from "./StatsCard";
import { useApi } from "../../util/api";
import SubscriptionCard from "./SubscriptionCard";

const containerStyle = (theme) => ({
  paddingTop: theme.spacing(10),
});

const Dashboard = () => {
  const [waitlist, setWaitlist] = useState(null);
  const fetcheWrapper = useApi();
  const [waitlistData, setWaitlistData] = useState({
    guestReady: 0,
    guestWaiting: 0,
    partiesReady: 0,
    partiesWaiting: 0,
  });
  const [dashData, setDashData] = useState({
    id: 0,
    averageWaitTime: 0,
    lastWait1to4: 0,
    lastWait5toX: 0,
    guestServed: 0,
    partiesServed: 0,
    noShowParties: 0,
    resetDate: 0,
  });



  useEffect(() => {

    if (waitlist === null) {
      return;
    }

    fetcheWrapper
      .get(`/admin/waitlist/${waitlist.id}/party?status=WAITING,READY`)
      .then((data) => {
        console.log(data);
        let guestWaiting = data
          .filter((row) => row.status === "WAITING")
          .reduce((result, row) => result + parseInt(row.guestNumber), 0);
        let partiesWaiting = data.filter(
          (row) => row.status === "WAITING"
        ).length;

        let guestReady = data
          .filter((row) => row.status === "READY")
          .reduce((result, row) => result + parseInt(row.guestNumber), 0);
        let partiesReady = data.filter((row) => row.status === "READY").length;

        const update = {
          guestWaiting: guestWaiting,
          partiesWaiting: partiesWaiting,
          guestReady: guestReady,
          partiesReady: partiesReady,
        };
        setWaitlistData({ ...waitlistData, ...update });
      });
  }, [waitlist]);

  useEffect(() => {
    if (waitlist === null) {
      return;
    }
    fetcheWrapper
      .get(`/admin/waitlist/${waitlist.id}/dashboard`)
      .then((data) => {
        console.log(data);
        //let waitlistid = data[0].id;
        //console.log("waitlistid: " + waitlistid);
        setDashData(data);
      });
  }, [waitlist]);

  useEffect(() => {
    console.log("=== Dashboard === ")
    fetcheWrapper.get("/admin/waitlist").then((data) => {
      //console.log(data);
      //let waitlistid = data[0].id;
      //console.log("waitlistid: " + waitlistid);
      setWaitlist(data[0]);
    });
  }, []);

  return (
    <Container sx={containerStyle}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <StatsCard
            Title="Guest Waiting"
            Value={waitlistData.guestWaiting}
            Duration="Now"
          ></StatsCard>
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            Title="Parties Waiting"
            Value={waitlistData.partiesWaiting}
            Duration="Now"
          ></StatsCard>
        </Grid>

        <Grid item xs={3}>
          <StatsCard
            Title="Guests Ready"
            Value={waitlistData.guestReady}
            Duration="Now"
          ></StatsCard>
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            Title="Parties Ready"
            Value={waitlistData.partiesReady}
            Duration="Now"
          ></StatsCard>
        </Grid>
      </Grid>

      <br></br>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <StatsCard
            Title="Average Wait Time"
            Value={dashData.averageWaitTime}
            Duration="Min"
          ></StatsCard>
        </Grid>
        <Grid item xs={4}>
          <StatsCard
            Title="Last Wait (1-4)"
            Value={dashData.lastWait1to4}
            Duration="Min"
          ></StatsCard>
        </Grid>

        <Grid item xs={4}>
          <StatsCard
            Title="Last Wait (5+)"
            Value={dashData.lastWait5toX}
            Duration="Min"
          ></StatsCard>
        </Grid>
      </Grid>

      <br></br>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <StatsCard
            Title="Guest Served"
            Value={dashData.guestServed}
            Duration= 'Since Reset'
          ></StatsCard>
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            Title="Parties Served"
            Value={dashData.partiesServed}
            Duration='Since Reset'
          ></StatsCard>
        </Grid>

        <Grid item xs={6}>
          <SubscriptionCard></SubscriptionCard>
        </Grid>
      </Grid>

      <br></br>
    </Container>
  );
};

export default Dashboard;
