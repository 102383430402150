import { ConstructionOutlined } from "@mui/icons-material";
import { Box, Container, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useApi } from "../../../util/api";
import SelfCheckinEnable from "./SelfCheckinEnable";
import WLQRCode from "./WLQRCode";
import SelfWaitTime from "./SelfWaitTime";

const SelfCheckin = ({ handleAlertOpen, waitlist }) => {
  const fetchWrapper = useApi();
  const [selfService, setSelfService] = useState(null);

  useEffect(() => {
    fetchWrapper
      .get("/user/selfservice")
      .then((data) => {
        setSelfService(data);
        console.log(data);
      })
      .catch((error) => {
        handleAlertOpen(error);
      });
  }, []);

  const handleRefresh = () => {
    console.log("handleRefresh");
    const body = JSON.stringify({
      id: selfService.id,
    });
    console.log(body);
    fetchWrapper
      .put("/user/selfservice/qr", body)
      .then((data) => {
        setSelfService(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ width: "400px" }}>
      <WLQRCode
        waitlistid={waitlist.id}
        url={selfService == null ? "" : selfService.url}
        handleRefresh={handleRefresh}
      ></WLQRCode>
      <br></br>
      <br></br>
      <br></br>

      <SelfCheckinEnable
        waitlistid={waitlist.id}
        selfService={selfService}
      ></SelfCheckinEnable>
      <br></br>
      <br></br>
      <br></br>
      <SelfWaitTime
        selfService = {selfService}
        handleAlertOpen = {handleAlertOpen}
      ></SelfWaitTime>
    </Box>
  );
};

export default SelfCheckin;
