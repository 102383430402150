import { Button, Container, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import QRCode from "qrcode.react";

function WLQRCode({ waitlistid, url, handleRefresh }) {
  const [Url, setUrl] = useState(url)
  const downloadQR = () => {
    const e1 = document.getElementById("qrcode");
    const qrCodeURL = e1
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const refreshQr = () => {
    handleRefresh()
  }

  return (
    <Container>
      <Typography align="left" variant="h5">
        Self Checkin QR Code
      </Typography>
      <Divider></Divider>
      Download to save the image of the QRCode which you can include in signage
      to facilitate self service checkin.
      <br></br>
      <QRCode id="qrcode" value={url} size={200} />
      <br></br>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        style={{ margin: "8px 0" }}
        value="Download"
        onClick={downloadQR}
      >
    
        Download
      </Button>
      {"   "}
      <Button
        type="submit"
        color="primary"
        variant="contained"
        style={{ margin: "8px 0" }}
        onClick={refreshQr}
      >
        {" "}
        Refresh
      </Button>
      <br></br>
      URL to signup: {url}
    </Container>
  );
}

export default WLQRCode;
