import React, { useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Container,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useUserActions } from "../../action/useUserAction";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/authAtom";
import SecurityIcon from "@mui/icons-material/Security";
const MContainer = styled(Container)(({ theme }) => ({
  color: "white",
  paddingTop: theme.spacing(10),
  backgroundColor: theme.palette.primary.main,
  position: "sticky",
  top: 0,
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "white",
    color: "#555",
    border: "0px solid #ece7e7",
  },
}));

const paperStyle = {
  padding: 20,
  height: "275",
  width: 350,
  margin: "20px auto",
};
const avatarStyle = { backgroundColor: "#e69138" };
const btnstyle = { margin: "8px 0" };

const SecurityChallenge = () => {
  const params = useLocation();
  const [formData, setFormData] = useState({
    email: params.state? params.state.userId : '',
    code: "",
  });

  const { email, code } = formData;
  const userActions = useUserActions();


  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [mesg, setMesg] = useState("");


  const setAlertMesg = (open, mesg) => {
    setMesg(mesg);
    setOpen(open);
  }


  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMesg(false, "");
  };

  const onChange = (e) => {
    
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    
    userActions.authenticateSecurityCode(email, code).catch((error) => {
      console.log("error login");
      setAlertMesg(true, error);
    });
  };

  const handleResendChallenge = (event) => {
    console.log(`handleResendChallenge: ${email}`)
    userActions.resendChallenge(email).then(()=> {
      setMesg("Security Code resent.")
      setOpen(true)
    }).catch((error) => {
      console.error(error);
      setAlertMesg(true, error);
    })
  }


  return (
    <MContainer>
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <Avatar style={avatarStyle}>
              <SecurityIcon />
            </Avatar>
            <h2>Security Challenge</h2>
          </Grid>
          
            <TextField
              required
              id="standard-required1"
              name="email"
              label="Email"
              placeholder="Enter email"
              value={email}
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
              InputProps={{
                readOnly: true,
              }}
            />
            <br></br>
            <TextField
              required
              id="standard-required"
              name="code"
              label="Security Code"
              placeholder="Enter 6 character code"
              value={code}
              type="text"
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
              onClick={handleSubmit}
            >
              Authenticate Code
            </Button>
          
          
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
              onClick={handleResendChallenge}
            >
              Resend Code
            </Button>
          
          <Typography>
            {" "}
            Check your mobile or email for security code.
          </Typography>
        </Paper>
      </Grid>

      <Snackbar open={open} autoHideDuration={15000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="warning"
          sx={{ width: "500px" }}
        >
          {mesg}
        </Alert>
      </Snackbar>
    </MContainer>
  );
};

export default SecurityChallenge;
