import { Container } from '@mui/material';
import React, { Component, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown';
import file from '../md/help.md'
import Markdown from 'react-markdown';
const containerStyle = (theme) => ({
    paddingTop: theme.spacing(10),
  });
function UserGuide() {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        console.log("=== UserGuide ===")
      fetch(file)
        .then((res) => res.text())
        .then((text) => {
            setMarkdown(text)});
    }, []);
  
    return (
      <Container sx={containerStyle}>

        <ReactMarkdown children={markdown}></ReactMarkdown>
      </Container>
    );
}

export default UserGuide