import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MenuIcon from "@mui/icons-material/Menu";

import { useApi } from "../../util/api";

function createData(
  number,
  gname,
  guests,
  quote,
  checkin,
  note,
  id,
  ready,
  mobile,
  status
) {
  return {
    number,
    gname,
    guests,
    quote,
    checkin,
    note,
    id,
    ready,
    mobile,
    status,
  };
}

const boxStyle = (theme) => ({
  paddingTop: theme.spacing(10),
});

const HistoryWaitlist = () => {
  const [datarow, setDatarow] = React.useState([]);
  const [waitlist, setWaitlist] = React.useState(null);

  const fetcheWrapper = useApi();

  const handleIcon = (id) => {
    console.log("handleIcon");
    console.log(id);
  };

  const handleDelete = (id) => {
    console.log("handleDelete");
    console.log(id);

    return fetcheWrapper
    .delete(`/admin/waitlist/${waitlist.id}/party/${id}`)
    .then((data) => {
      removeRow(id);
      
    })
    .catch((mesg) => {
      //setErrorMesg(mesg);
      console.error(mesg)
    });


  }

  const removeRow = (id) => {
    var array = []
    var index = 1;
    datarow.forEach(element => {
      if(element.id !== id) {
        array.push(
          createData(
            index++,
            element.gname,
            element.guests,
            element.quote,
            element.checkin,
            element.note,
            element.id,
            element.ready,
            element.mobile,
            element.status
          )
        )

      }
    });
    setDatarow(array);
  }

  React.useEffect(() => {

    datarow.length = 0;

    //setWaitlist(waitlist)
    if (waitlist === null) {
      return;
    }
    var waitlistid = waitlist.id;


    fetcheWrapper
      .get(`/admin/waitlist/${waitlistid}/party?status=SERVED,NOSHOW`)
      .then((data) => {
        console.log(data);
        var vnumber = 1;
        var array = [];
        data.forEach((element) => {
          array = [
            ...array,
            createData(
              vnumber,
              element.name,
              element.guestNumber,
              element.quotedTime,
              element.checkin,
              element.note,
              element.id,
              element.ready,
              element.mobile,
              element.status
            ),
          ];
          vnumber = vnumber + 1;
        });
        setDatarow(array);
      });
  }, [waitlist]);

  React.useEffect(() => {
    console.log("=== Historic Waitlist === ")

    fetcheWrapper.get("/admin/waitlist").then((data) => {
      console.log(data);
      setWaitlist(data[0]);
    });
  }, []);

  return (
    <Box>
      <Box sx={boxStyle}></Box>
      <Container>
        <Typography variant="h4" align="center">
          {waitlist ? waitlist.name : ""} Historic Waitlist
        </Typography>
      </Container>

      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ border: 0 }}>
              <TableCell>#</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Guests</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Min Waited</TableCell>

              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datarow.map((row) => (
              <TableRow
                key={row.number}
                hover
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.number}
                </TableCell>
                <TableCell align="left">
                  <Typography>{row.gname}</Typography>
                  <Typography sx={{ fontSize: 13 }}>
                    {row.mobile ? "mobile:" + row.mobile : ""}
                  </Typography>
                </TableCell>
                <TableCell align="right">{row.guests}</TableCell>
                <TableCell align="right">{row.status}</TableCell>

                <TableCell align="right">
                  <Typography sx={{ color: "#FF5733" }}>
                    {row.ready
                      ? +parseInt(
                          (row.ready * 1000 - row.checkin * 1000) / 60000
                        )
                      : ""}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                <Tooltip title="Delete">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handleDelete(row.id)}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Modify">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleIcon}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HistoryWaitlist;
