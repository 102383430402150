import { Box, Container, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LabelToolbar from "./LabelToolbar";
import LabelTable from "./LabelTable";
import { useApi } from "../../../util/api"


function Label({ handleAlertOpen, waitlist }) {
  const fetchWrapper = useApi();
  const [datarow, setDatarow] = React.useState([]);
  const [, setCount] = useState(0);

  useEffect(() => {
    if (waitlist == null)
      return;
    fetchWrapper.get(`/user/labels/${waitlist.id}`)
      .then((data) => {
        console.log("read labels")
        console.log(data)
        setDatarow(data)
      })

  }, [])

  const handleAdd = (labelName) => {
    const body = JSON.stringify({
      waitlistId: waitlist.id,
      name: labelName
    })
    console.log(body)

    return fetchWrapper.post("/user/label", body)
      .then((data) => {
        datarow.push(data)
        setCount((c) => c + 1);
      })
      .catch((mesg) => {
        handleAlertOpen(mesg)
      });
  }

  const handleDelete = (id) => {
    return fetchWrapper.delete(`/user/label/${id}`)
      .then((data) => {
        const filterData = datarow.filter(row => row.id != id)
        setDatarow(filterData)
        //setCount((c) => c + 1);
      })
      .catch((mesg) => {
        console.log(mesg)
        handleAlertOpen(mesg)
      })
  }

  const handleCheckUpdate = (id, name, checked) => {
    const body = JSON.stringify({
      id: id,
      waitlistId: waitlist.id,
      name: name,
      selfService: checked
    })
    console.log(body)

    return fetchWrapper.put('/user/label', body)
      .then((data)=> {
        datarow.forEach((item) => {
          if(item.id.id === data.id) {
            item.name = data.name;
            item.selfService = data.selfService;
          }
        })
      })
      .catch((mesg) => {
        console.log(mesg)
        handleAlertOpen(mesg)
      })
  }



  return (
    <Box sx={{ width: "600px" }}>
      <Container>
        <Typography align="left" variant="h5">
          Labels
        </Typography>
        <Divider></Divider>
        <LabelToolbar addLabel={handleAdd}></LabelToolbar>
        <LabelTable data={datarow} deleteLabel={handleDelete} updateLabel={handleCheckUpdate}></LabelTable>

        <br></br>
      </Container>
    </Box>
  );
}

export default Label;