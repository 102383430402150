import React, { useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Container,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import { useUserActions } from "../../action/useUserAction";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/authAtom";
import { validateEmail } from "../../util/Validation";
import { fingerprintAtom } from "../../state/fingerprintAtom";
import { useEffect } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";


const paperStyle = {
  padding: 20,
  height: "300",
  width: 300,
  margin: "20px auto",
};
const avatarStyle = { backgroundColor: "#1bbd7e" };
const btnstyle = { margin: "8px 0" };

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const userActions = useUserActions();
  const auth = useRecoilValue(authAtom);
  const [errorMesg, setErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [fingerprint, setFingerprint] = useRecoilState(fingerprintAtom);

  useEffect(() => {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        console.log(result.visitorId);
        setFingerprint(result.visitorId);
      });
  }, []);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      validateEmail(email);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setOpen(true);
      return;
    }

    console.log("login: " + fingerprint);
    userActions
      .login3(email.toLowerCase(), password, fingerprint)
      .catch((error) => {
        console.log("error login: " + error);
        setErrorMessage(error);
        setOpen(true);
      });
  };

  if (auth) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Container sx={{ paddingTop: 10, maxWidth: 400 }}>
      <form onSubmit={handleSubmit} >
        <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center">

          <Grid align="center">
            <Avatar style={avatarStyle}>
              <LockIcon />
            </Avatar>
            <h2>Sign In</h2>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="standard-required1"
              name="email"
              label="Email"
              placeholder="Enter email"
              value={email}
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />

            <br></br>

            <TextField
              required
              id="standard-required"
              name="password"
              label="Password"
              placeholder="Enter password"
              value={password}
              type="password"
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="checkedB"
                  color="primary"
                  checked="true"
                  disabled="true"
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
            >
              Sign in
            </Button>

          </Grid>



          <Typography>
            <Link component={RouterLink} to="/forgotpwd">
              Forgot password ?
            </Link>
          </Typography>
          <Typography>
            {" "}

            <Link component={RouterLink} to="/register">
              Sign Up
            </Link>
          </Typography>
          <Typography> support@waitabout.com</Typography>
          <a
            target="_blank"
            href="https://waitabout.com/download"
            title="Anrdoid App"
          >
            {" "}
            Android App
          </a>
          <br></br>
          <a target="_blank" href="https://waitabout.com/terms" title="terms">
            Terms {"  "}
          </a>
          <br></br>



        </Grid>
      </form>

      <Snackbar open={open} autoHideDuration={15000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: "500px" }}
        >
          {errorMesg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
