import {  useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { authAtom } from "../state/authAtom";
export { useApi };

function useApi() {
  const url = process.env.REACT_APP_API_URL;
  const [auth, setAuth] = useRecoilState(authAtom);
  const navigate = useNavigate();
  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
    get2: requestPromise("GET"),
  };

  function request(method) {
    return (path, body) => {
      const requestOptions = {
        method,
        headers: authHeader(),
      };
      if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = body;
      }

      return fetch(url + path, requestOptions).then(handleResponse);
    };
  }

  function requestPromise(method) {
    return (path, body) => {
      const requestOptions = {
        method,
        headers: authHeader(),
      };
      if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = body;
      }
      return fetch(url + path, requestOptions);
    };
  }

  // helper functions

  function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = auth?.accessToken;
    const isLoggedIn = !!token;

    if (isLoggedIn) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  }

  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        console.log("response:  " + response.status);
        //console.log("token:" + auth.accessToken)
        if ([401, 403].includes(response.status) ) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          sessionStorage.removeItem("waitlist-user");
          setAuth(null);
          navigate("/login")
        }

        const error = (data && data.message) || response.statusText || text;
        return Promise.reject(error);
      }

      return data;
    });
  }
}
