import React, { useState } from "react";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MenuIcon from "@mui/icons-material/Menu";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useApi } from "../../util/api";
import ModifyReservationDialog from "./ModifyReservationDialog";
import { useRecoilState } from "recoil";
import { alertOpenAtom } from "../../state/alertOpenAtom";
import { alertMesgAtom } from "../../state/alertMesgAtom";
const boxStyle = (theme) => ({
  paddingTop: theme.spacing(2),
});

export const ReservationListView2 = (props) => {
  const datarow = props.data;
  const {waitlistid, refresh} = props;
  const [, setCount] = useState(0);
  const fetcheWrapper = useApi();
  const [rsvOpen, setRsvOpen] = React.useState(false);
  const [rsvId, setRsvId] = React.useState(0);
  const [alertOpen, setAlertOpen] = useRecoilState(alertOpenAtom);
  const [alertMessage, setAlertMessage] = useRecoilState(alertMesgAtom);
  const handleNotification = (id) => {
    console.log("Notify " + id);
    fetcheWrapper
      .put(`/admin/waitlist/${waitlistid}/reservation/${id}/notify`)
      .then((data) => {
        console.log(data);
        datarow.forEach((row) => {
          if (row.id === id) {
            row.status = data.status;
          }
          // force a re-render by running:
          setCount((c) => c + 1);
        });
      }).catch((mesg) => {
        console.log(mesg)
        setAlertMessage(mesg)
        setAlertOpen(true);
      });
  };

  const handleComplete = (id) => {

    console.log("Complete " + id);
    fetcheWrapper
      .put(`/admin/waitlist/${waitlistid}/reservation/${id}/complete`)
      .then((data) => {
        console.log(data);
        datarow.forEach((row) => {
          if (row.id === id) {
            row.status = data.status;
          }
          // force a re-render by running:
          setCount((c) => c + 1);
        });
      });
  };



  const handlModifyRsvOpen = (id) => {
    setRsvId(id);
    setRsvOpen(true);
  };

  const handlModifyRsvClose = () => {
    setRsvOpen(false)
    refresh();
  }


  return (
    <Box>
      <Box sx={boxStyle}></Box>

      <TableContainer component={Paper} variant="outlined" >
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ border: 0 }}>
              <TableCell>Time</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Guests</TableCell>
              <TableCell align="right">Label</TableCell>
              <TableCell align="right">Resource</TableCell>
              <TableCell align="right">_</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datarow.map((row) => (
              <TableRow
                key={row.id}
                hover
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {new Date(row.reservationTime * 1000).toLocaleTimeString()}
                </TableCell>
                <TableCell align="left">
                  <Typography>{row.name}</Typography>
                  <Typography sx={{ fontSize: 13 }}>
                    {row.note ? "note:" + row.note : ""}
                  </Typography>
                </TableCell>
                <TableCell align="right">{row.guestNumber}</TableCell>
                <TableCell align="right">{row.label}</TableCell>
                <TableCell align="right">{row.quote}</TableCell>
                <TableCell align="right">
                  <Typography sx={{ color: "#FF5733" }}>-</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={{ color: "#097969" }}>
                    {row.status}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Notify Party">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handleNotification(row.id)}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <NotificationsActiveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Completed">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handleComplete(row.id)}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Modify">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handlModifyRsvOpen(row.id)}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ModifyReservationDialog open={rsvOpen} waitlistid = {props.waitlistid} onClose={handlModifyRsvClose} reservationId = {rsvId}></ModifyReservationDialog>
    </Box>
  );
};

ReservationListView2.propTypes = {
  waitlistid: PropTypes.number,
  onClose: PropTypes.func,
  data: PropTypes.array,
  refresh: PropTypes.func
};
