import {
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useApi } from "../../util/api";
import Dashboard from "./Dashboard";
const GeneralDetail = ({ handleAlertOpen }) => {
  const fetchWrapper = useApi();
  const [formData, setFormData] = React.useState({
    location: "",
    externalId: "",
    gmtoffset: 'GMT_M04',
  });
  const { location, externalId, gmtoffset } = formData;

  const [offset, setOffset] = React.useState('GMT_M04');


  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleTimeZoneChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setOffset(value)

    console.log(formData)
    console.log(`handletz ${value}`)

  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const body = JSON.stringify({
      location: formData.location,
      externalId: formData.externalId,
      gmtoffset: formData.gmtoffset,
    });
    return fetchWrapper
      .put("/user/gensetting", body)
      .then((data) => {
        setFormData(data);
      })
      .catch((mesg) => {
        console.log(mesg);
        handleAlertOpen(mesg);
      });
  };

  useEffect(() => {
    fetchWrapper.get("/user/gensetting").then((data) => {
      console.log(data)
      setFormData(data);
      return data
      
    }).then((data) => {
      console.log(data.gmtoffset)
      setOffset(data.gmtoffset)
    });
  }, []);

  return (
    <Box sx={{ width: "400px" }}>
      <Container>
        <Typography align="left" variant="h5">
          General
        </Typography>
        <Divider></Divider>
        <form onSubmit={handleSubmit}>
          <TextField
            id="standard-required1"
            name="location"
            label="Location Name"
            value={location}
            fullWidth
            margin="normal"
            size="small"
            onChange={onChange}
            inputProps={{ maxLength: 25 }}
          />
          <TextField
            id="standard-required2"
            name="externalId"
            label="External ID"
            value={externalId}
            fullWidth
            margin="normal"
            size="small"
            onChange={onChange}
            inputProps={{ maxLength: 50 }}
          />

          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="demo-simple-select-label">GMT OFFSET</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="gmtoffset"
              id="demo-simple-select"
              value={offset}
              label="GMT OFFSET"
              onChange={handleTimeZoneChange}
              
            >
              <MenuItem value={'GMT_M04'}>GMT-4</MenuItem>
              <MenuItem value={'GMT_M05'}>GMT-5</MenuItem>
              <MenuItem value={'GMT_M06'}>GMT-6</MenuItem>
              <MenuItem value={'GMT_M07'}>GMT-7</MenuItem>
              
            </Select>
          </FormControl>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: "8px 0" }}
          >
            Update
          </Button>
        </form>
        <br></br>
      </Container>
      <Dashboard></Dashboard>
    </Box>
  );
};

export default GeneralDetail;
