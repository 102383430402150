import { AccountCircle, Notifications } from "@mui/icons-material";
import {
  AppBar,
  Toolbar,
  Typography,
  Badge,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUserActions } from "../../action/useUserAction";
const Navbar = () => {
  const toolbarStyle = { display: "flex", justifyContent: "space-between" };
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const userActions = useUserActions();
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    console.log("handleMenu");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("logout")
    userActions.logout();
    handleClose();
  };


  return (
    <AppBar position="fixed">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">@waitabout</Typography>

        <div>
          <Badge badgeContent={0} color="secondary">
            <Notifications />
          </Badge>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            
          >
            <Link to="/profile" style={{ textDecoration: "none" }}>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
            </Link>
            <Link to="/setting" style={{ textDecoration: "none" }}>
              <MenuItem onClick={handleClose}>Settings</MenuItem>
            </Link>
            <Link to="/login" style={{ textDecoration: "none" }}>
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Link>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
