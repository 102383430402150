import { Box, Divider, TextField, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useApi } from "../../../util/api";
import PropTypes from "prop-types";

function SelfWaitTime({selfService, handleAlertOpen}) {
  const fetchWrapper = useApi();
  const [formData, setFormData] = React.useState({
    id:"",
    waitTime: selfService == null ? -1 : selfService.waitTime,
    waitlistId: ""
  });

  const {  waitTime } = formData;


  useEffect(() => {
    console.log("useeffect=>SelfWaitTime");
    if (selfService != null)  {
      console.log("useeffect=>SelfWaitTime==============");
      //setFormData({ ...formData, id: selfService.id });
      setFormData({ ...formData, waitTime: selfService.waitTime });
      //setFormData({ ...formData, waitlistId: selfService.waitlistId });
    }
  }, [selfService]);


  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //setFormData({ ...formData, id: selfService.id });
    //setFormData({ ...formData, waitlistId: selfService.waitlistId });
    formData.id = selfService.id;
    formData.waitlistId = selfService.waitlistId; 
    setFormData({...formData});
    console.log(formData);
    const body = JSON.stringify(formData);
    console.log(body);
    return fetchWrapper
    .put("/user/selfservice/wt", body)
    .then((data) => {
      setFormData(data);
    })
    .catch((mesg) => {
      console.log(mesg);
      handleAlertOpen(mesg);
    });
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Typography align="left" variant="h5">
          Custom wait time
        </Typography>
        <Divider></Divider>
        <Typography align="left" variant="caption">
          This is the wait time presented to the guest.
        </Typography>
        <br></br>
        <Typography align="left" variant="caption">
          Enter '-1' to use system control wait time.
        </Typography>
        <Box sx={{ padding: 3 }}>
          <TextField
          autoFocus
            id="waitTime"
            label="Wait time (minutes)"
            name="waitTime"
            value={waitTime}
            type="number"
            onChange={onChange}
            inputProps={{min:-1}}
          />
        </Box>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={{ margin: "8px 0" }}
        >
          Update
        </Button>
        <Divider></Divider>
      </form>
    </Box>
  );
}

SelfWaitTime.propTypes = {
  id: PropTypes.number,
  waitlistId: PropTypes.number,
  waitTime: PropTypes.number,
};

export default SelfWaitTime;
