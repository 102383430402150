import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Profile from "./Profile";
import PasswordChange from "./PasswordChange";
import GeneralDetail from "./GeneralDetail";
import TextMessage from "./TextMessage";
import Subscription from "./Subscription";
import { Alert, Snackbar } from "@mui/material";
import SelfCheckin from "./selfservice/SelfCheckin";
import { useApi } from "../../util/api";
import UpgradePaypal from "./UpgradePaypal";
import Reservation from "./reservation/Reservation";
import StoreHour from "./StoreHour";
import Resource from "./resource/Resource";
import Label from "./labels/Label";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const boxStyle = (theme) => ({
  paddingTop: theme.spacing(10),
});

const Setting = ({ settingValue }) => {
  const [value, setValue] = React.useState(parseInt(settingValue));
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [waitlist, setWaitlist] = React.useState(null);
  const fetchWrapper = useApi();
  React.useEffect(() => {
    const getwl = async () => {
      const response = await fetchWrapper.get("/admin/waitlist");
      console.log(response[0])
      setWaitlist(response[0])
    };
    getwl()
    console.log(waitlist)
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAlertOpen = (message) => {
    setOpen(true);
    setErrorMessage(message);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage("");
    setOpen(false);
  };

  return (
    <div>
      <Box sx={boxStyle}></Box>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: 600,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Profile" {...a11yProps(0)} />

          <Tab label="Password Change" {...a11yProps(1)} />
          <Tab label="Subscription" {...a11yProps(2)} />
          <Tab label="General Detail" {...a11yProps(3)} />
          <Tab label="Labels" {...a11yProps(4)} />
          <Tab label="Text Message" {...a11yProps(5)} />
          <Tab label="Self Service" {...a11yProps(6)} />
          <Tab label="Reservation" {...a11yProps(7)} />
          
          <Tab label="Resources" {...a11yProps(8)} />
          
          <Tab label="Upgrade" {...a11yProps(9)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Profile handleAlertOpen={handleAlertOpen}></Profile>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PasswordChange handleAlertOpen={handleAlertOpen}></PasswordChange>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Subscription></Subscription>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <GeneralDetail handleAlertOpen={handleAlertOpen}></GeneralDetail>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Label handleAlertOpen={handleAlertOpen} waitlist = {waitlist}></Label>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <TextMessage></TextMessage>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <SelfCheckin waitlist = {waitlist}/>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Reservation/>
        </TabPanel>
        
        <TabPanel value={value} index={8}>
          <Resource></Resource>
        </TabPanel>
      
        <TabPanel value={value} index={9}>
          <UpgradePaypal></UpgradePaypal>
        </TabPanel>
      </Box>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: "500px" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Setting;
