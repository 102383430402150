import { Box, Typography, Divider } from "@mui/material";
import React, { useEffect } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { ReadMoreSharp } from "@mui/icons-material";
import { useApi } from "../../util/api";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Subscription = () => {
  const fetchWrapper = useApi();
  const [formData, setFormData] = React.useState({
    planName: "",
    planLimit: "",
    remaining: "",
  });
  const { planName, planLimit, remaining } = formData;

  useEffect(() => {
    fetchWrapper.get("/user/subscription").then((data) => {
      setFormData(data);
    });
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  var progress = (parseInt(remaining) / parseInt(planLimit)) * 100;

  return (
    <Box sx={{ width: "400px" }}>
      <Typography align="left" variant="h5">
      Subscription Details
      </Typography>
      <Divider></Divider>
      <h3>Plan: {planName}</h3>
      <Typography>{planLimit} Guest parties for this plan</Typography> <br></br>
      <BorderLinearProgress variant="determinate" value={progress} />
      <Typography align="center">
        {remaining} of {planLimit} remaining
      </Typography>
    </Box>
  );
};

export default Subscription;
