import { Container, styled } from "@mui/material";
import React from "react";

const MContainer = styled(Container)(({ theme }) => ({
  height: "auto",
  color: "white",
  paddingTop: theme.spacing(5),
  backgroundColor: theme.palette.primary.main,
  position: "sticky",
  top: 0,
  border: 0,
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "white",
    color: "#555",
    border: "0px solid #ece7e7",
  },
}));

const RegisterAbout = () => {
  return (
    <div>
      <MContainer>
        <h3>Easily Add Customers</h3>
        Add customers to your waitlist quickly while managing time and
        customer's expectations.
        <h3>Notify Guests</h3>
        Send your guests text messages letting them know when it's their turn.
        <h3>Guest Self Checkin</h3>
        Allows scanning of QR code to allow guest to self register.
        <h3>Guest View</h3>
        Provides guest ability to see their place in line.
        <h3>Works On Modern Browsers</h3>
        Waitlist works on modern web browsers allowing you to easily manage
        services. Compatible with computing platforms with attached keyboard (includes chromebooks and android/ios devices) or standalone tablets in portrait mode 
        with a minimal aprox 768 x1024 resolution
      </MContainer>
    </div>
  );
};

export default RegisterAbout;
