import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { useApi } from "../../util/api";
import PropTypes from "prop-types";
import { DateTimePicker } from "@mui/x-date-pickers";
import { validateFullname, validateMinGuest, validatePhone2 } from "../../util/Validation";
const ModifyReservationDialog = (props) => {
  const { waitlistid, onClose, open, reservationId } = props;
  const fetchWrapper = useApi();
  const [date, setDate] = React.useState(new Date());
  const [errorMesg, setErrorMesg] = React.useState("");
  const [formData, setFormData] = React.useState({
    id: "",
    waitlistid: "",
    name: "",
    guestNumber: "",
    duration: "60",
    note: "",
    mobile: "",
    uid: "",
    date: new Date(),
    status: "RESERVED",
  });

  const { id, name, guestNumber, duration, note, mobile, uid } = formData;

  React.useEffect(() => {
    if (!open) return;
    console.log("modify useeffect");

    fetchWrapper
      .get(`/admin/waitlist/${waitlistid}/party/${reservationId}`)
      .then((data) => {
        console.log(data);
        setFormData(data);
        var d = new Date(data.reservationTime * 1000);
        
        setDate(d)
      });
  }, [open]);

  const handleClose = () => {
    resetFormData();
    setErrorMesg("");
    onClose();
  };

  /*
  const handleSubmit = () => {
    console.log(formData);

    return (
      fetchWrapper
        .post(
          `/admin/waitlist/${waitlistid}/reservation`,
          JSON.stringify(formData)
        )
        //.post(`/admin/waitlist/143/reservation`, JSON.stringify(formData))
        .then((data) => {
          handleClose();
        })
        .catch((mesg) => {
          console.log("handleSubmit " + mesg);
          setErrorMesg(mesg);
        })
    );
  };*/
  const handleSubmit = () => {
    //p_waitlistid = 16
    setFormData({ ...formData, waitlistid: waitlistid });
    console.log(formData);
    try {
      console.log(mobile)
      validateFullname(name);
      validatePhone2(mobile);
      validateMinGuest(guestNumber);
      //validatePassword(password, password2);
      //validatePhone(mobile);
    } catch (error) {
      console.log(error);
      setErrorMesg(error.message);
      
      return;
    }
    return fetchWrapper
      .put(`/admin/waitlist/${waitlistid}/party/${reservationId}`, JSON.stringify(formData))
      .then((data) => {
        handleClose();
      })
      .catch((mesg) => {
        setErrorMesg(mesg);
      });
  };

  const handleNoshow = () => {
    setFormData({ ...formData, waitlistid: waitlistid });
    console.log(formData);

    return fetchWrapper
      .put(`/admin/waitlist/${waitlistid}/reservation/${reservationId}/noshow`, JSON.stringify(formData))
      .then((data) => {
        handleClose();
      })
      .catch((mesg) => {
        setErrorMesg(mesg);
      });
  }

  const handleCancelReservation = () => {
    setFormData({ ...formData, waitlistid: waitlistid });
    console.log(formData);

    return fetchWrapper
      .put(`/admin/waitlist/${waitlistid}/reservation/${reservationId}/cancel`, JSON.stringify(formData))
      .then((data) => {
        handleClose();
      })
      .catch((mesg) => {
        setErrorMesg(mesg);
      });
  }


  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetFormData = () => {
    setFormData({
      id: "",
      waitlistid: "",
      name: "",
      guestNumber: "",
      duration: "60",
      note: "",
      mobile: "",
      uid: "",
    });
    setDate(new Date());
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Add Reservation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Reservation details into the fields below. List mobile number as
            0000000000 if no mobile is availiable.
          </DialogContentText>
          <DateTimePicker
            label="Reservation Time"
            renderInput={(params) => <TextField {...params} />}
            value={date}
            onChange={(newValue) => {
              newValue.setSeconds(0, 0);
              setDate(newValue);
              setFormData({ ...formData, date: newValue });
            }}
            size="small"
            minutesStep={Number("5")}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            value={name}
            label="Customer Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            onChange={onChange}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="mobile"
            name="mobile"
            value={mobile}
            label="Mobile (xxxyyyzzzz)"
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            onChange={onChange}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 10,
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="guestNumber"
            name="guestNumber"
            value={guestNumber}
            label="Number of Guests"
            type="number"
            size="small"
            variant="outlined"
            onChange={onChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="duration"
            name="duration"
            value={duration}
            label="Duration"
            type="number"
            maxWidth="xs"
            variant="outlined"
            size="small"
            onChange={onChange}
          />
          <div></div>
          <TextField
            autoFocus
            id="note"
            label="Notes"
            name="note"
            value={note}
            multiline
            rows={3}
            fullWidth
            sx={{ marginTop: "10px" }}
            onChange={onChange}
          />
          <Typography sx={{ color: "red", marginTop: "10px" }}>
            {errorMesg}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleNoshow}>No Show</Button>
          <Button onClick={handleCancelReservation}>Cancel Rsv</Button>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModifyReservationDialog.propTypes = {
  waitlistid: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  reservationId: PropTypes.number,
};

export default ModifyReservationDialog;
