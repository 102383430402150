import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";

function ResourceToolbar(props) {
  const [rsvOpen, setRsvOpen] = React.useState(false);

  const handelRsvClickOpen = () => {
    setRsvOpen(true);
  };

  const handleRsvClose = () => {
    setRsvOpen(false);
    //refresh();
  };
  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: 1,
        }}>
          <div>Add staff names or resources such as "tables-4-seats"</div>
        
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handelRsvClickOpen}
            disabled
          >
            Add Resource
          </Button>

        </Box>
      </Box>
    </Box>
  );
}

ResourceToolbar.propTypes = {};

export default ResourceToolbar;
