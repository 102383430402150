import {
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApi } from "../../util/api";

function Dashboard() {
  const [waitlist, setWaitlist] = useState(null);
  const fetchWrapper = useApi();
  const [dashData, setDashData] = useState({
    id: 0,
    averageWaitTime: 0,
    lastWait1to4: 0,
    lastWait5toX: 0,
    guestServed: 0,
    partiesServed: 0,
    noShowParties: 0,
    resetDate: 0,
  });

  useEffect(() => {
    if (waitlist === null) {
      return;
    }
    fetchWrapper
      .get(`/admin/waitlist/${waitlist.id}/dashboard`)
      .then((data) => {
        console.log(data);
        //let waitlistid = data[0].id;
        //console.log("waitlistid: " + waitlistid);
        setDashData(data);
      });
  }, [waitlist]);

  useEffect(() => {
    fetchWrapper.get("/admin/waitlist").then((data) => {
      //console.log(data);
      //let waitlistid = data[0].id;
      //console.log("waitlistid: " + waitlistid);
      setWaitlist(data[0]);
    });
  }, []);

  const handleSubmit = (event) => {
    fetchWrapper
    .put(`/admin/waitlist/${waitlist.id}/dashboard/reset`)
    .then((data) => {
        setDashData(data);
    })
    .catch((mesg) => {
      console.log(mesg);
      //handleAlertOpen(mesg);
    });

    ///admin/waitlist/{waitlistid}/dashboard/reset
  }

  

  return (
    <div>
      <Container sx={{ mt: 2 }}>
          <form onSubmit={handleSubmit}>
        <Typography align="left" variant="h5" xs={{ marginTop: "200" }}>
          Reset Dashboard Data
        </Typography>
        <Divider></Divider>
        <Typography align="left">
          This will reset the avg wait, last wait times, guest served, and
          parties served values.
        </Typography>
        <TextField
          id="standard-required2"
          name="externalId"
          label="Last Reset Time"
          value={new Date(dashData.resetDate*1000).toLocaleString()}
          fullWidth
          margin="normal"
          size="small"
          inputProps={{ maxLength: 50, readOnly: true }}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={{ margin: "8px 0" }}
          
        >
          Reset
        </Button>
        </form>
        <br></br>
        <Divider></Divider>
      </Container>
    </div>
  );
}

export default Dashboard;
