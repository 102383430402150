
const emailPattern =/^\w+([\.-/+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

function validateFullname(input) {
    validateEmpty(input);
}

function validateEmail(input) {
    validateEmpty(input);
    if(!input.match(emailPattern)) {
        throw new Error("Email pattern is not valid.")
    }
}

function validatePhone(input) {
    validateEmpty(input);
    if(!input.match(phonePattern)) {
        throw new Error("Phone number should be in xxx-yyy-zzzz or xxxyyyzzzz format.")
    }
}

function validatePhone2(input) {
    if(!input || input.length !== 10) {
        throw new Error("Phone number must be in xxxyyyzzzz format and 10 digits");
    }
}

function validatePassword(input1, input2) {
    validateEmpty(input1)
    validateEmpty(input2)
    if(input1 !== input2) {
        throw new Error("Password must match.")
    }
}

function validateEmpty(input) {
    if(!input || input.length === 0) {
        throw new Error("Input cannot be empty");
    }
}

function validateSecurityChallenge(input) {
    validateEmpty(input)
    if(input.length !== 6) {
        throw new Error("Security Code must be 6 digits");
    }
}

function validateMinGuest(input) {
    if(!input || input < 1) {
        throw new Error("PValue must be greater than 0");
    }
}


export {validateFullname, validateEmail, validatePhone, validatePhone2, validatePassword, validateEmpty, validateSecurityChallenge, validateMinGuest}