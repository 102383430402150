import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Divider, LinearProgress, linearProgressClasses } from "@mui/material";
import { useApi } from "../../util/api";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const SubscriptionCard = ({ Title, Value, Duration }) => {
  const fetchWrapper = useApi();
  const [formData, setFormData] = React.useState({
    planName: "",
    planLimit: "",
    remaining: "",
  });
  const { planName, planLimit, remaining } = formData;

  React.useEffect(() => {
    fetchWrapper
      .get("/user/subscription")
      .then((data) => {
        console.log(data);
        setFormData(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  var progress = (parseInt(remaining) / parseInt(planLimit)) * 100;
  return (
    <Card sx={{ minWidth: 100, border: 1, paddingBottom: 0.5 }}>
      <CardContent>
        <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
          Subscription
        </Typography>
        <Typography>{planLimit} Guest parties for this plan</Typography>{" "}
        <br></br>
        <BorderLinearProgress variant="determinate" value={progress} />
        <Typography align="center">
          {remaining} of {planLimit} remaining
        </Typography>
        <Typography variant="body2">
          <br></br>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SubscriptionCard;
