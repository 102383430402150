

import React, { useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Container,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import { useUserActions } from "../../action/useUserAction";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/authAtom";
import {
  validateEmail
} from "../../util/Validation";
const MContainer = styled(Container)(({ theme }) => ({
  color: "white",
  paddingTop: theme.spacing(10),
  backgroundColor: theme.palette.primary.main,
  position: "sticky",
  top: 0,
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "white",
    color: "#555",
    border: "0px solid #ece7e7",
  },
}));

const paperStyle = {
  padding: 20,
  height: "275",
  width: 350,
  margin: "20px auto",
};
const avatarStyle = { backgroundColor: "#1bbd7e" };
const btnstyle = { margin: "8px 0" };


const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: ""

  });

  const { email, password } = formData;
  const userActions = useUserActions();
  const auth = useRecoilValue(authAtom);
  const [errorMesg, setErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      validateEmail(email);
    } catch(error) {
      console.log(error);
      setErrorMessage(error.message);
      setOpen(true);
      return;
    }


    userActions.resetpwd(email.toLowerCase())
    .catch((error) => {
      console.log("error login: " + error);
      setErrorMessage(error);
      setOpen(true);
      
    });

  };

  if (auth) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <MContainer>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Paper elevation={10} style={paperStyle}>
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <RestartAltOutlinedIcon />
              </Avatar>
              <h2>Password Reset</h2>
              <p>A email will be sent to the addressee with a new password.</p>
            </Grid>
            <TextField
              required
              id="standard-required1"
              name="email"
              label="Email"
              placeholder="Enter email"
              value={email}
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />
            <br></br>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
            >
              Reset Password
            </Button>


            <Typography>
              {" "}
              Support: wngtechnology@gmail.com
            </Typography>
          </Paper>
        </Grid>
      </form>

      <Snackbar open={open} autoHideDuration={15000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '500px' }}>
          {errorMesg}
        </Alert>
      </Snackbar>


    </MContainer>
  );
};

export default ResetPassword;
