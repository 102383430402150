import { Container, styled, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import HistoryIcon from "@mui/icons-material/History";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import React from "react";
import { Link } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import Tooltip from "@mui/material/Tooltip";
import AndroidIcon from '@mui/icons-material/Android';
const MContainer = styled(Container)(({ theme }) => ({
  height: "100vh",
  color: "grey",
  border: "10px black",
  paddingTop: theme.spacing(10),
  backgroundColor: theme.palette.primary.main,
  position: "sticky",
  top: 0,
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "white",
    color: "#555",
    border: "0px solid #ece7e7",
  },
}));

const MDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(4),
  color: "black",
  "&:hover": {
    color: "blue",
  },
}));

const iconStyle = (theme) => ({
  marginRight: theme.spacing(1),
});

const Leftbar = () => {
  return (
    <MContainer>
      <Link to="/dashboard" style={{ textDecoration: "none" }}>
        <Tooltip title="Dashboard">
          <MDiv>
            <DashboardIcon sx={iconStyle}></DashboardIcon>
          </MDiv>
        </Tooltip>
      </Link>
      <Link to="/waitlist" style={{ textDecoration: "none" }}>
        <Tooltip title="Waitlist">
          <MDiv>
            <ListIcon sx={iconStyle}></ListIcon>
          </MDiv>
        </Tooltip>
      </Link>
      <Link to="/history" style={{ textDecoration: "none" }}>
        <Tooltip title="History">
          <MDiv>
            <HistoryIcon sx={iconStyle}></HistoryIcon>
          </MDiv>
        </Tooltip>
      </Link>

      <Link to="/reservation" style={{ textDecoration: "none" }}>
        <Tooltip title="Reservation">
          <MDiv>
            <EventSeatIcon sx={iconStyle}></EventSeatIcon>
          </MDiv>
        </Tooltip>
      </Link>
      <Link to="/guide" style={{ textDecoration: "none" }}>
        <Tooltip title="User Guide">
          <MDiv>
            <ArticleIcon sx={iconStyle}></ArticleIcon>
          </MDiv>
        </Tooltip>
      </Link>
      <a href="https://waitabout.com/download" target='_blank' rel='noreferrer'>
      <Tooltip title="Android Download">
          <MDiv>
            <AndroidIcon sx={iconStyle}></AndroidIcon>
          </MDiv>
        </Tooltip>
        </a>
    </MContainer>
  );
};

export default Leftbar;
