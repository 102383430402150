import React from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField } from "@mui/material";

function LabelToolbar(props) {
  const [rsvOpen, setRsvOpen] = React.useState(false);

  const handelRsvClickOpen = () => {
    setRsvOpen(true);
  };

  const handleRsvClose = () => {
    setRsvOpen(false);
    //refresh();
  };

  const [formData, setFormData] = React.useState({
    label:""
  });
  const {label} = formData;

  const onChange = (event) => {
    const {name, value} = event.target;
    setFormData({...formData, [name] : value});
  }

  const handleSubmit = (event) => {
    //event.preventDefault();
    if(formData.label.trim() === "") {
      console.log("empty formdata")
      return
    }
    console.log(formData.label)
    props.addLabel(formData.label)

  }


  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: 1,
        }}>
        
        <Box sx={{ m: 1 }}>
            <TextField
                        id="standard-required1"
                        name="label"
                        label="Label Name"
                        value={label}
                        fullWidth
                        margin="normal"
                        size="small"
                        onChange={onChange}
                        inputProps={{ maxLength: 15 }}></TextField>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            
          >
            Add Label
          </Button>

        </Box>
      </Box>
    </Box>
  );
}

LabelToolbar.propTypes = {
  waitlistid: PropTypes.number,
  addLabel: PropTypes.func,  
};

export default LabelToolbar;
