import { Box, Divider, TextField, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useApi } from "../../util/api";

const TextMessage = () => {
  const fetchWrapper = useApi();
  const [formData, setFormData] = React.useState({
    welcomeText: "",
    notificationText: "",
    waitTime: "",
  });

  useEffect(() => {
    fetchWrapper.get("/user/textmessage").then((data) => {
      setFormData(data);
    });
  }, []);

  const { welcomeText, notificationText, waitTime } = formData;
  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const body = JSON.stringify(formData);
    console.log(body);
  };
  return (
    <Box width="400px">
      <form onSubmit={handleSubmit}>
        <Typography align="left" variant="h5">
          Text Message
        </Typography>
        <Divider></Divider>
        <Box sx={{ padding: 3 }}>
          <TextField
            id="outlined-multiline-static"
            label="Welcome Text"
            name="welcomeText"
            value={welcomeText}
            multiline
            rows={3}
            fullWidth
            onChange={onChange}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box sx={{ padding: 3 }}>
          <TextField
            id="outlined-multiline-static"
            label="Notification Text"
            multiline
            rows={3}
            name="notificationText"
            value={notificationText}
            fullWidth
            onChange={onChange}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

      </form>
    </Box>
  );
};

export default TextMessage;
