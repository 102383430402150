import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Typography } from "@mui/material";
import { useApi } from "../../util/api";
import {
  validateFullname,
  validatePhone2,
  validateMinGuest
} from "../../util/Validation";
const ModifyWlFormDialog = ({ p_waitlistid, p_partyid, open, onClose, removeRow }) => {
  const fetchWrapper = useApi();

  const [errorMesg, setErrorMesg] = React.useState("");
  const [formData, setFormData] = React.useState({
    id: "",
    waitlistid: p_waitlistid,
    name: "",
    guestNumber: "",
    quotedTime: "",
    note: "",
    mobile: "",
    uid: "",
    status: ""
  });

  const [phreadonly, setPhreadOnly] = React.useState(true)

  const { id, waitlistid, name, guestNumber, quotedTime, note, mobile, uid , status} =
    formData;
  React.useEffect(() => {
    if (!open) return;
    console.log("modify useeffect");

    fetchWrapper
      .get(`/admin/waitlist/${p_waitlistid}/party/${p_partyid}`)
      .then((data) => {
        console.log(data);
        setFormData(data);
      });
  }, [open]);

  const handleClose = () => {
    resetFormData();
    setErrorMesg("");
    onClose(false);
  };

  const handleSubmit = () => {
    //p_waitlistid = 16
    setFormData({ ...formData, waitlistid: p_waitlistid });
    console.log(formData);


    try {
      validateFullname(name);
      validatePhone2(mobile);
      validateMinGuest(guestNumber);
    } catch (error) {
      console.log(error);
      setErrorMesg(error.message);
      return;
    }

    return fetchWrapper
      .put(`/admin/waitlist/${p_waitlistid}/party/${p_partyid}`, JSON.stringify(formData))
      .then((data) => {
        handleClose();
      })
      .catch((mesg) => {
        setErrorMesg(mesg);
      });
  };


  const handleDelete = () => {
    return fetchWrapper
      .delete(`/admin/waitlist/${p_waitlistid}/party/${p_partyid}`, JSON.stringify(formData))
      .then((data) => {
        removeRow(p_partyid);
        handleClose();
      })
      .catch((mesg) => {
        setErrorMesg(mesg);
      });
  };
  const handleNoshow = () => {
    return fetchWrapper
      .put(`/admin/waitlist/${p_waitlistid}/party/${p_partyid}/noshow`, JSON.stringify(formData))
      .then((data) => {
        removeRow(p_partyid);
        handleClose();
      })
      .catch((mesg) => {
        setErrorMesg(mesg);
      });
  };

  const onChange = (event) => {
    
    const { name, value } = event.target;
    
    if(name === 'mobile' && formData.status === 'WAITING') {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    
  };

  const resetFormData = () => {
    setFormData({
      id: "",
      waitlistid: "",
      name: "",
      guestNumber: "",
      quotedTime: "",
      note: "",
      mobile: "",
      uid: "",
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth='xs'>
        <DialogTitle>Modify Party</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Modify Customer Party details into the fields below. List mobile
            number as 0000000000 if no mobile is availiable.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            value={name}
            label="Customer Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            onChange={onChange}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            autoFocus
            margin="dense"
            id="mobile"
            name="mobile"
            value={mobile}
            label="Mobile (xxxyyyzzzz)"
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            onChange={onChange}

            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' ,  maxLength: 10}}
          />

          <TextField
            autoFocus
            margin="dense"
            id="guestNumber"
            name="guestNumber"
            value={guestNumber}
            label="Number of Guests"
            type="number"
            size="small"
            variant="outlined"
            onChange={onChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="quotedTime"
            name="quotedTime"
            value={quotedTime}
            label="Quoted Time (0-180) min"
            type="number"
            maxWidth="xs"
            variant="outlined"
            size="small"
            onChange={onChange}
          />
          <div></div>
          <TextField
            autoFocus
            id="note"
            label="Notes"
            name="note"
            value={note}
            multiline
            rows={3}
            fullWidth
            sx={{ marginTop: "10px" }}
            onChange={onChange}
          />
          <Typography sx={{ color: "red", marginTop: "10px" }}>
            {errorMesg}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={handleNoshow}>No Show</Button>
          <Button onClick={handleDelete}>Delete</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModifyWlFormDialog;
