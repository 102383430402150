import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MenuIcon from "@mui/icons-material/Menu";
import WlFormDialog from "./WlFormDialog";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useApi } from "../../util/api";
import ModifyWlFormDialog from "./ModifyWlFormDialog";
import { useRecoilState } from "recoil";
import { alertOpenAtom } from "../../state/alertOpenAtom";
import { alertMesgAtom } from "../../state/alertMesgAtom";
function createData(number, gname, guests, quote, checkin, note, id, ready, label) {
  return { number, gname, guests, quote, checkin, note, id, ready, label };
}

const boxStyle = (theme) => ({
  paddingTop: theme.spacing(10),
});

const Waitlist = () => {
  const [datarow, setDatarow] = React.useState([]);
  const [waitlist, setWaitlist] = React.useState(null);
  const [modOpen, setModOpen] = React.useState(false);
  const [partyid, setPartyId] = React.useState(0);
  const [alertOpen, setAlertOpen] = useRecoilState(alertOpenAtom);
  const [alertMessage, setAlertMessage] = useRecoilState(alertMesgAtom);
  const fetcheWrapper = useApi();

  const handleIcon = (id) => {
    console.log("handleIcon");
    console.log(id);
    setPartyId(id);
    setModOpen(true);
  };
  const handleClickOpen = () => {
    setModOpen(true);
  };

  const handleClose = (value) => {
    setModOpen(false);
    //setSelectedValue(value);
  };
  const handleComplete = (id) => {
    console.log("Complete " + id);

    fetcheWrapper
      .put(`/admin/waitlist/${waitlist.id}/party/${id}/complete`)
      .then((data) => {
        removeRow(id);
      });
  };

  const removeRow = (id) => {
    var array = [];
    var index = 1;
    datarow.forEach((row) => {
      if (row.id !== id) {
        array.push(
          createData(
            index++,
            row.gname,
            row.guests,
            row.quote,
            row.checkin,
            row.note,
            row.id,
            row.ready,
            row.label
          )
        );
      }
    });
    console.log("removed row id " + id);
    console.log(array);
    setDatarow(array);
  };

  const handleNotification = (id) => {
    console.log("Notify " + id);
    fetcheWrapper
      .put(`/admin/waitlist/${waitlist.id}/party/${id}/notify`)
      .then((data) => {
        console.log(data);
        var newData = datarow.map((row) => {
          let ready = row.ready;
          if (row.id === id) ready = data.ready;
          return createData(
            row.number,
            row.gname,
            row.guests,
            row.quote,
            row.checkin,
            row.note,
            row.id,
            ready,
            row.label
          );
        });
        setDatarow(newData);
      }).catch((mesg)=> {
        console.log(mesg)
        setAlertMessage(mesg)
        setAlertOpen(true);
      });
  };

  React.useEffect(() => {

    datarow.length = 0;

    //setWaitlist(waitlist)
    if (waitlist === null) {
      return;
    }
    var waitlistid = waitlist.id;
    console.log(waitlist);

    fetcheWrapper
      .get(`/admin/waitlist/${waitlistid}/party?status=WAITING,READY`)
      .then((data) => {
        console.log(data);
        var vnumber = 1;
        var array = [];
        data.forEach((element) => {
          array = [
            ...array,
            createData(
              vnumber,
              element.name,
              element.guestNumber,
              element.quotedTime,
              element.checkin,
              element.note,
              element.id,
              element.ready,
              element.label
            ),
          ];
          vnumber = vnumber + 1;
        });
        setDatarow(array);

      });
  }, [waitlist]);


  const [labeldata, setLabeldata] = React.useState(['default'])

  React.useEffect(() => {
        //setWaitlist(waitlist)
        if (waitlist === null) {
          return;
        }
        var waitlistid = waitlist.id;
    console.log("add dialog user effect " + waitlistid)

    return fetcheWrapper.get(`/user/labels/${waitlistid}`)
      .then((data) => {
        console.log("read labels")
        console.log(data)
        setLabeldata(data)
      })
  }, [waitlist])

  
  React.useEffect(() => {
    console.log("=== Waitlist ===");
    fetcheWrapper.get("/admin/waitlist").then((data) => {
      console.log(data);
      setWaitlist(data[0]);
    });
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      console.log("60 second screen refresh");

      var newData = datarow.map((row) => {
        //let ready =
        return createData(
          row.number,
          row.gname,
          row.guests,
          row.quote,
          row.checkin,
          row.note,
          row.id,
          row.ready,
          row.label
        );
      });
      //const n = datarow;
      console.log("size " + datarow.length);
      setDatarow(newData);
    }, 60000);
    return () => clearInterval(interval);
  }, [datarow]);

  const addWlData = (party) => {
    console.log("=== add party ===");

    let array = [...datarow];
    array.push(
      createData(
        datarow.length + 1,
        party.name,
        party.guestNumber,
        party.quotedTime,
        party.checkin,
        party.note,
        party.id,
        party.ready,
        party.label
      )
    );
    setDatarow(array);
  };

  const updateWlData = (party) => {
    console.log("=== update party ===");

    let array = [...datarow];
    array.push(
      createData(
        datarow.length + 2,
        party.name,
        party.guestNumber,
        party.quotedTime,
        party.checkin,
        party.note,
        party.id,
        party.ready,
        party.label
      )
    );
    setDatarow(array);
  };

  return (
    <Box>
      <Box sx={boxStyle}></Box>
      <Container>
        <Typography variant="h4" align="center">
          {waitlist ? waitlist.name : ""} Waitlist
        </Typography>
      </Container>

      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ border: 0 }}>
              <TableCell>#</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Guests</TableCell>
              <TableCell align="right">Label</TableCell>
              <TableCell align="right">Quoted Wait</TableCell>
              <TableCell align="right">Checkin</TableCell>
              <TableCell align="right">Ready</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datarow.map((row) => (
              <TableRow
                key={row.number}
                hover
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.number}
                </TableCell>
                <TableCell align="left">
                  <Typography>{row.gname}</Typography>
                  <Typography sx={{ fontSize: 13 }}>
                    {row.note ? "note:" + row.note : ""}
                  </Typography>
                </TableCell>
                <TableCell align="right">{row.guests}</TableCell>
                <TableCell align="right">{row.label}</TableCell>
                <TableCell align="right">{row.quote}</TableCell>
                <TableCell align="right">
                  <Typography sx={{ color: "#FF5733" }}>
                    -{parseInt((Date.now() - row.checkin * 1000) / 60000)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={{ color: "#097969" }}>
                    {row.ready
                      ? "+" + parseInt((Date.now() - row.ready * 1000) / 60000)
                      : ""}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Notify Party">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handleNotification(row.id)}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <NotificationsActiveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Completed">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handleComplete(row.id)}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Modify">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handleIcon(row.id)}
                      color="inherit"
                      sx={{ padding: 0.5 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <WlFormDialog
        p_waitlistid={waitlist ? waitlist.id : 0}
        addWlData={addWlData}
        labeldata={labeldata}
      ></WlFormDialog>

      <ModifyWlFormDialog
        open={modOpen}
        onClose={handleClose}
        p_waitlistid={waitlist ? waitlist.id : 0}
        p_partyid={partyid}
        removeRow={removeRow}
      ></ModifyWlFormDialog>
    </Box>
  );
};

export default Waitlist;
