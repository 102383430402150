import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const StatsCard = ({ Title, Value, Duration }) => {
  return (
    <Card sx={{ minWidth: 100, border: 1 }}>
      <CardContent>
        <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
          {Title}
        </Typography>
        <Typography variant="h3" component="div">
          {Value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {Duration}
        </Typography>
        <Typography variant="body2">
          <br />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
