import { useSetRecoilState } from "recoil";

import { useApi } from "../util/api";
import { authAtom } from "../state/authAtom";
import { history } from "../util/history";
import { useNavigate } from "react-router-dom";

export { useUserActions };

function useUserActions() {
  const fetchWrapper = useApi();
  const setAuth = useSetRecoilState(authAtom);
  const navigate = useNavigate();

  return {
    login,
    logout,
    logout2,
    register,
    login3,
    resendChallenge,
    authenticateSecurityCode,
    resetpwd
  };

  async function login3(username, password, fingerprint) {
    const body = JSON.stringify({
      usernameOrEmail: username,
      password: password,
      fpid: fingerprint
    });
    const response = await fetchWrapper.put(
      '/auth/isapproved', body
    );

    if (response.success === true) {
      const user = await fetchWrapper.post("/auth/signin", body);
      sessionStorage.setItem("waitlist-user", JSON.stringify(user));
      setAuth(user);
    } else {
      navigate("/challenge", {
        state: {
          userId: username,
        },
      });
    }
  }


  function authenticateSecurityCode(email, code) {
      const body = JSON.stringify({
          id: email,
          code: code
      })
      return fetchWrapper.put('/auth/challenge', body)
        .then((response) => {
            console.log(response);
            if(response.success === true) {
                navigate("/login");
            }
        })
  }


  function login(username, password) {
    const body = JSON.stringify({
      usernameOrEmail: username,
      password: password,
    });
    return (
      fetchWrapper
        .get(`/auth/isapproved?email=${username}`)
        .then((data) => {
          console.log("check isapproved");
          console.log(data);
          if (data.success === true) {
            console.log("here is true");
          } else {
            console.log("here is false");
            navigate("/challenge", { email: username });
            return;
          }
        })

        //console.log('login' + body);
        .then(() => {
          console.log("LOGIN");
          fetchWrapper.post("/auth/signin", body).then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("waitlist-user", JSON.stringify(user));
            setAuth(user);

            // get return url from location state or default to home page
            //const { from } = history.location.state || { from: { pathname: '/' } };
            //history.push('/dashboard');
            //history.forward()
          });
        })
    );
  }




  

  function resendChallenge(email) {
      return fetchWrapper.get(`/auth/challenge?email=${email}`);
  }


  function register(name, email, password, mobile, fpid) {
    const body = JSON.stringify({
      name: name,
      email: email,
      username: email,
      password: password,
      mobile: mobile,
      fpid: fpid
    });
    return fetchWrapper.post("/auth/signup", body).then((user) => {
      navigate("/login");
    });
  }


  function resetpwd(email) {
    const body = JSON.stringify({
      email: email,
    });
    return fetchWrapper.post("/auth/resetpwd", body).then((user) => {
      navigate("/login");
    });
  }

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    sessionStorage.removeItem("waitlist-user");
    setAuth(null);
    navigate("/login");
  }

  function logout2() {
    sessionStorage.removeItem("waitlist-user");
    setAuth(null);
  }
}
