import {
  Container,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../util/api";
function SelfCheckinEnable({ waitlistid, selfService }) {
  const [checked, setChecked] = useState(null);
  const fetchWrapper = useApi();

  useEffect(() => {
    console.log("useeffect=>");
    if (selfService != null && checked == null) {
      console.log("use effect init");
      setChecked(selfService.active);
    }
  });

  const handleChange = (event) => {
    console.log("handle");
    console.log(event.target.checked);
    setChecked(event.target.checked);
    const body = JSON.stringify({
      id: selfService.id,
      active: event.target.checked,
    });
    console.log(body);
    fetchWrapper
      .put("/user/selfservice", body)
      .then((response) => {
        console.log("updated enable status");
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Typography align="left" variant="h5">
        Enable Self Checkin
      </Typography>
      <Divider></Divider>
      <Container sx={{ mt: 2 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={checked == null ? false : checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Enable Guest Self Checkin"
          />
        </FormGroup>
      </Container>
    </div>
  );
}

export default SelfCheckinEnable;
