import { Box, Container, Divider, Typography } from "@mui/material";
import React from "react";
import History from "../../reservation/History";

import ResourceTable from "./ResourceTable";
import ResourceToolbar from "./ResourceToolbar";

function Resource() {
  return (
    <Box sx={{ width: "600px" }}>
      <Container>
        <Typography align="left" variant="h5">
          Resources
        </Typography>
        <Divider></Divider>
        <ResourceToolbar></ResourceToolbar>
        <ResourceTable></ResourceTable>

        <br></br>
      </Container>
    </Box>
  );
}

export default Resource;
