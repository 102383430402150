import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApi } from "../../util/api";

const Profile = ({ handleAlertOpen }) => {
  const fetchWrapper = useApi();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    fetchWrapper.get("/user/profile").then((data) => {
      setFormData(data);
    });
  }, []);

  const { name, email } = formData;

  const handleSubmit = (event) => {
    event.preventDefault();
    const body = JSON.stringify({ name: formData.name });
    return fetchWrapper
      .put("/user/profile", body)
      .then((data) => {
        setFormData(data);
      })
      .catch((mesg) => {
        console.log(mesg);
        handleAlertOpen(mesg);
      });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Box sx={{ width: "400px" }}>
      <form onSubmit={handleSubmit}>
        <Typography align="left" variant="h5">
          Profile Update
        </Typography>
        <Divider></Divider>
        <TextField
          required
          id="standard-required1"
          name="name"
          label="Fullname"
          value={name}
          fullWidth
          margin="normal"
          size="small"
          onChange={onChange}
        />
        <TextField
          required
          id="standard-required2"
          label="Email"
          name="email"
          value={email}
          fullWidth
          margin="normal"
          size="small"
          InputProps={{
            readOnly: true,
          }}
          onChange={onChange}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={{ margin: "8px 0" }}
        >
          Update
        </Button>
      </form>
    </Box>
  );
};

export default Profile;
