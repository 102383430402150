import { Box } from "@mui/system";
import React from "react";
import { ReservationListView2 } from "./ReservationListView2";
import { ReservationToolbar } from "./ReservationToolbar";
import PropTypes from "prop-types";
import { useApi } from "../../util/api";

const boxStyle = (theme) => ({
  paddingTop: theme.spacing(10),
});
function Reservations() {
  const [date, setDate] = React.useState(new Date());
  const fetcheWrapper = useApi();
  const [waitlist, setWaitlist] = React.useState(null);
  const [datarow, setDatarow] = React.useState([]);
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    console.log("=== Date Use Effect === ");
    if(waitlist == null)
      return;
    const month = date.getMonth()+1;
    const day = date.getDate();
    const year = date.getFullYear();
    fetcheWrapper
      //.get(`/admin/waitlist/${waitlistid}/party?status=SERVED,NOSHOW`)
      //.get(`/admin/waitlist/143/reservation?month=${month}&day=${day}&year=${year}`)
      .get(`/admin/waitlist/${waitlist.id}/reservation?month=${month}&day=${day}&year=${year}`)
      .then((data) => {
        console.log(data);
        setDatarow(data)
      });
  }, [date, waitlist, count]);


  React.useEffect(() => {
    console.log("=== Waitlist ===");
    fetcheWrapper.get("/admin/waitlist").then((data) => {
      console.log(data);
      setWaitlist(data[0]);
    });
  }, []);


  const [labeldata, setLabeldata] = React.useState(['default'])

  React.useEffect(() => {
    //setWaitlist(waitlist)
    if (waitlist === null) {
      return;
    }
    var waitlistid = waitlist.id;
    console.log("add dialog user effect " + waitlistid)

    return fetcheWrapper.get(`/user/labels/${waitlistid}`)
      .then((data) => {
        console.log("read labels")
        console.log(data)
        setLabeldata(data)
      })
  }, [waitlist])



  const onDateChange = (date) => {
    console.log(`Reservation Date ${date}`);
    setDate(date);
  };


  const reservationRefresh =() => {
    setCount(count + 1)
  } 

  return (
    <Box sx={boxStyle}>
      <ReservationToolbar 
        onDateChange={onDateChange} 
        waitlistid = {waitlist == null ? 0 : waitlist.id} 
        refresh = {reservationRefresh}
        labelData={labeldata}
      >

      </ReservationToolbar>
      <ReservationListView2 data = {datarow} waitlistid = {waitlist == null ? 0 : waitlist.id} refresh = {reservationRefresh}></ReservationListView2>
    </Box>
  );
}

export default Reservations;
