import React from 'react'
import { Container, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material'
function Online() {
    const [checked, setChecked] = React.useState(false);
    const handleChange = (event) => {
    }
  return (
    <Container sx={{ mt: 2 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Enable Online Reservation "
          disabled
        />
      </FormGroup>
      <TextField
            id="standard-required1"
            name="location"
            label="Location Name"
            value="https://res.waitabout.com/f2dms"
            fullWidth
            margin="normal"
            size="small"
            disabled
            inputProps={{ maxLength: 25 }}
          />
    </Container>
  )
}

export default Online