import { Container, FormControlLabel, FormGroup, Switch } from '@mui/material'
import React from 'react'

function ReservationEnable() {
    const [checked, setChecked] = React.useState(true);
    const handleChange = (event) => {
    }
  return (
    <Container sx={{ mt: 2 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Enable Reservation"
          disabled
        />
      </FormGroup>
    </Container>
  )
}

export default ReservationEnable