import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useApi } from "../../util/api";
import {
  validateEmail,
  validateFullname,
  validateMinGuest,
  validatePassword,
  validatePhone2,
} from "../../util/Validation";
const WlFormDialog = ({ p_waitlistid, addWlData, labeldata }) => {
  const fetchWrapper = useApi();
  const [open, setOpen] = React.useState(false);
  const [errorMesg, setErrorMesg] = React.useState("");
  const [label, setLabel] = React.useState('');
  const [formData, setFormData] = React.useState({
    id: "",
    waitlistid: p_waitlistid,
    name: "",
    guestNumber: "1",
    quotedTime: "1",
    note: "",
    mobile: "",
    uid: "",
    label: label
  });

  const { id, waitlistid, name, guestNumber, quotedTime, note, mobile, uid } =
    formData;


  const handleClickOpen = () => {
    setOpen(true);
  };

  

  const handleChange = (event) => {
    console.log(event.target.value)
    setLabel(event.target.value);
    setFormData({ ...formData, 'label': event.target.value });
  };


  const handleClose = () => {
    resetFormData();
    setErrorMesg("");
    setOpen(false);
  };

  const handleSubmit = () => {
    //p_waitlistid = 16
    setFormData({ ...formData, waitlistid: p_waitlistid });
    console.log(formData);

    try {
      validateFullname(name);
      validatePhone2(mobile);
      validateMinGuest(guestNumber);
    } catch (error) {
      console.log(error);
      setErrorMesg(error.message);
      setOpen(true);
      return;
    }



    return fetchWrapper
      .post(`/admin/waitlist/${p_waitlistid}/party`, JSON.stringify(formData))
      .then((data) => {
        addWlData(data);
        handleClose();
      })
      .catch((mesg) => {
        console.log("handleSubmit " + mesg);
        setErrorMesg(mesg);
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetFormData = () => {
    setFormData({
      id: "",
      waitlistid: "",
      name: "",
      guestNumber: "1",
      quotedTime: "",
      note: "",
      mobile: "",
      uid: "",
      label: label
    });
  };

  return (
    <div>
      <Fab
        color="primary"
        onClick={handleClickOpen}
        aria-label="add"
        sx={{ position: "fixed", bottom: 100, right: 100 }}
      >
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={handleClose} maxWidth='xs' >
        <DialogTitle>Add Party</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Customer Party details into the fields below. List mobile number
            as 0000000000 if no mobile is availiable.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            value={name}
            label="Customer Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            onChange={onChange}

          />
          <TextField
            autoFocus
            margin="dense"
            id="mobile"
            name="mobile"
            value={mobile}
            label="Mobile (xxxyyyzzzz)"
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            onChange={onChange}
            inputProps={{ inputMode: 'text', pattern: '[0-9]*', maxLength: 10 }}
          />
          <div></div>
          <div></div><div></div>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Label</InputLabel>
            <Select
              labelId="label"
              id="label"
              value={label}
              label="label"
              onChange={handleChange}
              size="small"
            >
                        <MenuItem value="">
            <em>None</em>
          </MenuItem>
              {
                
                labeldata.map(
                  (label) => (
                    <MenuItem value={label.name}>{label.name}</MenuItem>
                  )

                )
              }

            </Select>
          </FormControl>
          <div></div>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '20ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
              autoFocus
              margin="dense"
              id="guestNumber"
              name="guestNumber"
              value={guestNumber}
              label="Number of Guests"
              type="number"
              size="small"
              variant="outlined"
              onChange={onChange}

            />

            <TextField
              autoFocus
              margin="dense"
              id="quotedTime"
              name="quotedTime"
              value={quotedTime}
              label="Quoted(0-180) min"
              type="number"

              variant="outlined"
              size="small"
              onChange={onChange}
            />
          </Box>
          <div></div>

          <div></div>
          <TextField
            autoFocus
            id="note"
            label="Notes"
            name="note"
            value={note}
            multiline
            rows={3}
            fullWidth
            sx={{ marginTop: "10px" }}
            onChange={onChange}
          />
          <Typography sx={{ color: "red", marginTop: "10px" }}>
            {errorMesg}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WlFormDialog;
