import { Divider, Typography } from "@mui/material";
import { height } from "@mui/system";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useState } from "react";
import { useApi } from "../../util/api";

function UpgradePaypal() {
  const fetcheWrapper = useApi();
  const [message, setMessage] = useState();

  const createOrder = () => {
    console.log("UpgradePaypal ");
    setMessage(" ");
    return fetcheWrapper
      .post(`/user/orderservice/1000`)
      .then((data) => {
        console.log("UpgradePaypal executed");
        console.log(data.id);
        return data.id;
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const approveOrder = (data) => {
    console.log("approveOrder ");
    console.log(data);
    return fetcheWrapper
      .post(`/user/order/${data.orderID}/capture`)
      .then((data) => {
        console.log("Paypal capture executed");
        console.log(data);
        return data.status;
      })
      .then((value) => {
        if (value === "COMPLETED") {
          setMessage(
            "Transaction executed correctly. Check your email for confirmation."
          );
        }
      })
      .catch((e) => {
        console.error(e);
        setMessage("Transaction failed.");
      });
  };

  return (
    <PayPalScriptProvider
      options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
    >
      <div>
        <div style={{ width: "350px" }}>
          <Typography align="left" variant="h5">
            1000 Subscription Credit
          </Typography>
          <Divider></Divider>
          <p>Price: $60.00</p>
          <p>
            Add 1000 subscription credits to your account. Credits expires 90
            days after last credit is added.
          </p>
          <PayPalButtons
            style={{ layout: "horizontal" }}
            createOrder={createOrder}
            onApprove={approveOrder}
          />
          <br></br>
          {message}
          <br></br>
          <p>Contact support@waitabout.com for billing issue and support.</p>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default UpgradePaypal;
