import { Alert, Box, Snackbar } from "@mui/material";
import Navbar from "./components/layout/Navbar";
import Grid from "@mui/material/Grid";
import Leftbar from "./components/layout/Leftbar";
import Rightbar from "./components/layout/Rightbar";
import Feed from "./components/Feed";
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
} from "react-router-dom";
import { Fragment } from "react";
import Waitlist from "./components/waitlist/Waitlist";
import Setting from "./components/setting/Setting";
import Login from "./components/acct/Login";
import Register from "./components/acct/Register";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { history } from "./util/history";
import HistoryWaitlist from "./components/waitlist/HistoryWaitlist";
import Dashboard from "./components/dashboard/Dashboard";
import UserGuide from "./components/UserGuide";
import SecurityChallenge from "./components/acct/SecurityChallenge";
import ResetPassword from "./components/acct/ResetPassword";
import Reservations from "./components/reservation/Reservations";
// date-fns
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { alertOpenAtom } from "./state/alertOpenAtom";
import { alertMesgAtom } from "./state/alertMesgAtom";


function App({ children }) {


  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {children}
        <Fragment>
          <BrowserRouter history={history}>
            <Box sx={{ flexGrow: 1 }}>
              <RecoilRoot>
                <Routes>
                  <Route path="/" element={<Navigate to="/register" />}></Route>
                  <Route
                    path="/login"
                    element={
                      <PublicRoute>
                        <Login />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <PublicRoute>
                        <Register />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/forgotpwd"
                    element={
                      <PublicRoute>
                        <ResetPassword />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/challenge"
                    element={
                      <PublicRoute>
                        <SecurityChallenge />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/waitlist"
                    element={
                      <PrivateRoute>
                        <Waitlist />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/history"
                    element={
                      <PrivateRoute>
                        <HistoryWaitlist />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/reservation"
                    element={
                      <PrivateRoute>
                        <Reservations />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/setting"
                    element={
                      <PrivateRoute>
                        <Setting settingValue="2" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <PrivateRoute>
                        <Setting settingValue="0" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/guide"
                    element={
                      <PrivateRoute>
                        <UserGuide />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </RecoilRoot>
            </Box>
          </BrowserRouter>
        </Fragment>
      </LocalizationProvider>
    </div>
  );
}

const PublicRoute = ({ children }) => {
  const view = (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          {children}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Fragment>
  );

  return view;
};

const PrivateRoute = ({ children }) => {
  const [alertOpen, setAlertOpen] = useRecoilState(alertOpenAtom);
  const [alertMessage, setAlertMessage] = useRecoilState(alertMesgAtom);

  function handleAlertClose() {
    setAlertMessage("");
    setAlertOpen(false);
  }
  const authed = true; // isauth() returns true or false based on localStorage
  var view = <Navigate to="/login" />;
  if (authed) {
    view = (
      <Fragment>
        <Navbar></Navbar>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <Leftbar />
          </Grid>
          <Grid item xs={10}>
            {children}
          </Grid>
          <Grid item xs={1}>
            <Rightbar></Rightbar>
          </Grid>
        </Grid>
        <Snackbar
          open={alertOpen}
          autoHideDuration={10000}
          onClose={handleAlertClose}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "500px" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }

  return view;
};

export default App;
