import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
function LabelRow(props) {

    const [checked, setChecked] = React.useState(props.selfService);

    const handleCheckmarkChange = (event) => {
        const value = !checked;
        setChecked(value)
        props.handleUpdate(props.id, props.name, value )
    };

    return (
        <TableRow
            key={props.id}
            hover
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >

            <TableCell align="left">
                <Typography>{props.name}</Typography>

            </TableCell>

            <TableCell align="right">
                <Checkbox
                    checked={checked}
                    onChange={handleCheckmarkChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />

            </TableCell>
            <TableCell align="right">

                <Tooltip title="Modify">
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => props.handleDelete(props.id)}
                        color="inherit"
                        sx={{ padding: 0.5 }}
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

LabelRow.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    selfService: PropTypes.bool,
    handleDelete: PropTypes.func,
    handleUpdate: PropTypes.func
}

export default LabelRow
